<template>
  <div class="page-bg">
    <div class="page">
      <toper
        :title="name"
        :back="true"
        :backFunc="true"
        @doBack="goIntention"
      ></toper>
      <div class="iframe">
        <iframe :src="link"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import { wxShare } from "@/assets/js/wechatAuth.js";
import toper from "../components/toper.vue";
export default {
  components: {
    toper,
  },
  data() {
    return {
      link: "",
      name: "",
      exhibitionId: "",
      productType: "",
    };
  },
  created() {
    this.exhibitionId = this.$route.query.exhibitionId;
    this.productType = this.$route.query.productType;
    this.getExhbition();
    wxShare({});
  },
  methods: {
    goIntention() {
      this.$http.get("/sale/h5/checkUserIntention").then((res) => {
        if (res.code === 200 && res.data) {
          if (!res.data || res.data.length == 0 || res.data == null) {
            this.$router.replace({
              name: "intentionQandA",
            });
          } else {
            this.$router.back();
          }
        }
      });
    },
    getExhbition() {
      this.$http
        .get("/sale/h5/exhibitionDetail", {
          id: this.exhibitionId,
          product_type: this.productType || 0
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.name = res.data.name;
            this.link = res.data.link;
          } else {
            this.$toast.fail(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f2f2f2;
}
.page-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.page {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  .iframe {
    flex: 1;
    width: 100%;
    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
}
@media screen and (orientation: landscape) {
  .page {
    min-width: 750px !important;
    max-width: 850px !important;
    margin: auto !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
  }
}
</style>
